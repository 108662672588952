import * as React from 'react';
import {Helmet} from 'react-helmet';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS, INLINES} from '@contentful/rich-text-types';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col, Image} from 'react-bootstrap';

const AktualnosciSinglePage = ({pageContext}) => {
  const news = pageContext.news;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const {title, file} = node.data.target.fields;
        return <Image src={`https:${file.url}`} alt={title} fluid rounded />;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
    },
  };

  return (
    <Layout>
      <Helmet>
        <title>{news.title} – Aktualności – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`${news.title} – Aktualności – Studenckie Koła Naukowe AGH`} />
        {news.image && <meta property="og:image" content={`https:${news.image}`} />}
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />
      <div className="news-single-page pt-5">
        <Container className="py-xl">
          <Row>
            <Col lg={10} xl={8}>
              <h1>{news.title}</h1>
              <span>{news.date}</span>
              {news.image && <Image src={news.image} fluid rounded />}
              <div className="news-single-page__content">{documentToReactComponents(news.content, options)}</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export default AktualnosciSinglePage;
